<template>
  <div v-if="shown" id="successPaymentPopup" class="modal">
    <div class="modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="close">
              <span aria-hidden="true">×</span>
            </button>
            <div class="row">
              <div class="col-md-12">
                <div id="popup-title">Thanks for upgrading</div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div id="popup-subtitle">Cheers to your new Research journey!</div>
              </div>
            </div>
            <div class="row">
              <button class="btn btn-primary btn-md continue" @click.prevent="close">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="confetti-land">
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

const shown = ref(true);
const confettiPlayers = ref<any[]>([]);

onMounted(() => {
  const confetti = document.querySelectorAll(".confetti");

  if (!confetti[0].animate) {
    return false;
  }

  for (let i = 0, len = confetti.length; i < len; ++i) {
    const candycorn = confetti[i] as HTMLElement;
    candycorn.innerHTML = '<div class="rotate"><div class="askew"></div></div>';
    const scale = Math.random() * 0.7 + 0.3;
    const player = candycorn.animate(
      [
        {
          transform: `translate3d(${(i / len) * 100}vw,-5vh,0) scale(${scale}) rotate(0turn)`,
          opacity: scale,
        },
        {
          transform: `translate3d(${(i / len) * 100 + 10}vw,105vh,0) scale(${scale}) rotate(${
            Math.random() > 0.5 ? "" : "-"
          }2turn)`,
          opacity: 1,
        },
      ],
      {
        duration: Math.random() * 3000 + 5000,
        iterations: Infinity,
        delay: -(Math.random() * 7000),
      }
    );

    confettiPlayers.value.push(player);
  }
});

const close = () => {
  shown.value = false;
  confettiPlayers.value.forEach((confetti) => {
    confetti.cancel();
  });
  const confettiLand = document.querySelector(".confetti-land");
  if (confettiLand) {
    confettiLand.remove();
  }
};
</script>

<style>
#successPaymentPopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

#successPaymentPopup .modal {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-y: scroll;
  background-color: rgb(8 8 8 / 30%);
  backdrop-filter: blur(3px);
  text-align: center;
  z-index: 1040;
}

#successPaymentPopup .modal-dialog {
  position: relative;
  z-index: 1050;
  width: auto;
  margin: 10px;
  min-width: 20vw;
  top: 25%;
}

@media screen and (min-width: 768px) {
  #successPaymentPopup .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
}

#successPaymentPopup .modal-content {
  position: relative;
  border-radius: 3px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  padding-top: 3em;
  background-clip: padding-box;
  background-color: #009476;
}

#successPaymentPopup .modal-header {
  font-family: system-ui;
  font-size: 2rem;
  color: white;
  padding-bottom: 8px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: none;
}

#successPaymentPopup .modal-body {
  padding: 2em 5em;
}

#successPaymentPopup #popup-title {
  font-weight: 600;
  text-align: center;
}

#successPaymentPopup #popup-subtitle {
  padding-bottom: 2em;
  font-size: 12px;
  color: whitefff;
  letter-spacing: 1px;
}

#successPaymentPopup button.close {
  position: absolute;
  font-size: 27px;
  top: 0.3em;
  color: white;
  background-color: transparent;
  border: 0;
  right: 0.5em;
  opacity: 0.2;
}

#successPaymentPopup button.close:hover {
  opacity: 0.5;
}

#successPaymentPopup .btn:hover {
  opacity: 0.92;
}

#successPaymentPopup .btn {
  background: white;
  border-color: white;
  color: #009476;
}

* {
  box-sizing: border-box;
}

.confetti {
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: 0;
  z-index: 150;
  color: hsl(343, 80%, 40%);
}

.confetti .rotate {
  animation: driftyRotate 1s infinite both ease-in-out;
  perspective: 1000;
}

.confetti:nth-child(4n + 1) {
  color: hsl(103, 80%, 30%);
}

.confetti:nth-child(4n + 2) {
  color: hsl(43, 80%, 40%);
}

.confetti:nth-child(4n + 3) {
  color: hsl(23, 70%, 40%);
}

.confetti .askew {
  background: currentColor
    linear-gradient(to right, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.12) 0% 52%, rgba(0, 0, 0, 0) 92%);
  transform: skewY(10deg);
  width: 2.5rem;
  height: 4.2rem;
  animation: drifty 1s infinite alternate both ease-in-out;
  perspective: 1000;
  clip-path: polygon(
    56% 0%,
    56% 16%,
    60% 20%,
    85% 40%,
    100% 60%,
    85% 75%,
    70% 90%,
    50% 100%,
    15% 75%,
    0% 60%,
    15% 40%,
    40% 20%,
    44% 16%,
    44% 0%
  );
}

.confetti:nth-of-type(7n) .askew {
  animation-delay: -0.6s;
  animation-duration: 2.25s;
}

.confetti:nth-of-type(7n + 1) .askew {
  animation-delay: -0.879s;
  animation-duration: 3.5s;
}

.confetti:nth-of-type(7n + 2) .askew {
  animation-delay: -0.11s;
  animation-duration: 1.95s;
}

.confetti:nth-of-type(7n + 3) .askew {
  animation-delay: -0.246s;
  animation-duration: 0.85s;
}

.confetti:nth-of-type(7n + 4) .askew {
  animation-delay: -0.43s;
  animation-duration: 2.5s;
}

.confetti:nth-of-type(7n + 5) .askew {
  animation-delay: -0.56s;
  animation-duration: 1.75s;
}

.confetti:nth-of-type(7n + 6) .askew {
  animation-delay: -0.76s;
  animation-duration: 1.5s;
}

.confetti:nth-of-type(9n) .rotate {
  animation-duration: 2s;
}

.confetti:nth-of-type(9n + 1) .rotate {
  animation-duration: 2.3s;
}

.confetti:nth-of-type(9n + 2) .rotate {
  animation-duration: 1.1s;
}

.confetti:nth-of-type(9n + 3) .rotate {
  animation-duration: 0.75s;
}

.confetti:nth-of-type(9n + 4) .rotate {
  animation-duration: 4.3s;
}

.confetti:nth-of-type(9n + 5) .rotate {
  animation-duration: 3.05s;
}

.confetti:nth-of-type(9n + 6) .rotate {
  animation-duration: 2.76s;
}

.confetti:nth-of-type(9n + 7) .rotate {
  animation-duration: 7.6s;
}

.confetti:nth-of-type(9n + 8) .rotate {
  animation-duration: 1.78s;
}

@keyframes drifty {
  0% {
    transform: skewY(10deg) translate3d(-250%, 0, 0);
  }

  100% {
    transform: skewY(-12deg) translate3d(250%, 0, 0);
  }
}

@keyframes driftyRotate {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(359deg);
  }
}
</style>
