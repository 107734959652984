import { defineStore } from "pinia";
import { WSClient } from "@/ems/WSClient";
import { Serie, Cat, Variable, Filter, FilterGroupLists } from "@/types/serie";
import { getUserPermission, Role } from "@/ems/perms";
import { handleCurrentSerieExtra } from "@/ems/format_extra";
import { DescriptiveSummary } from "@/views/series/descriptive/types";
import { ref, reactive } from "vue";

export const serieContextStore = defineStore("serie_context", () => {
  const serie_user_permissions = reactive({} as Record<string, boolean>);
  const serie = reactive({} as Serie);
  const serie_roles = ref<Role[]>([]);
  const descriptive = ref({} as DescriptiveSummary);
  const categories = ref<Cat[]>([]);
  const variables = ref<Variable[] | undefined>(undefined);
  const filters = ref<Filter[]>([]);
  const filter_history = ref<Filter[]>([]);
  const filter_groups = reactive({} as FilterGroupLists);
  const new_patient_created = ref(false);
  const shared_categorie_ids = ref<number[] | undefined>([]);
  const set_of_visible_cats = reactive(new Set<number>());
  const set_of_visible_vars = reactive(new Set<number | string>());

  const get_user_permissions = async (client: WSClient, preferences: string[]): Promise<Record<string, boolean>> => {
    if (!serie.SerieId) throw Error("Cannot get user without serie id set");
    const missingPreferences = preferences.filter((preference) => !(preference in serie_user_permissions));
    if (missingPreferences.length > 0) {
      if (!serie.SerieId) throw new Error("Cannot get permissions if serie id is not set");
      const queried_prefs = await getUserPermission(client, missingPreferences, serie.SerieId);
      Object.keys(queried_prefs).forEach((key) => {
        serie_user_permissions[key] = queried_prefs[key];
      });
    }
    const returned_permissions = Object.fromEntries(
      Object.entries(serie_user_permissions).filter(([key]) => preferences.includes(key))
    );
    return Promise.resolve(returned_permissions);
  };

  async function load_serie(client: WSClient, serie_id: string | number, force_query = false): Promise<Serie | null> {
    if (!force_query && serie.SerieId && serie_id == serie.SerieId) {
      return Promise.resolve(serie);
    }
    console.log("Openning serie " + serie_id);
    const result = await client.queryWs<{
      serie: Serie;
      roles: Role[];
      descriptive_summary: DescriptiveSummary;
      shared_categorie_ids?: number[];
    }>("GET", "serie_serie", {
      serie_id: serie_id,
      permissions: true,
      descriptive_summary: false,
      with_plan: true,
      roles: true,
      withProdTooltip: true,
    });

    if (!result.serie) return null;

    result.serie = handleCurrentSerieExtra(result.serie);
    descriptive.value = result.descriptive_summary;
    Object.assign(serie, result.serie);
    serie_roles.value = result.roles;
    shared_categorie_ids.value = result.shared_categorie_ids;
    return serie;
  }

  async function load_filter(client: WSClient): Promise<void> {
    const resp = await client.queryWs<{
      filters: Filter[];
      filter_history: Filter[];
      filter_groups: FilterGroupLists;
    }>("GET", "filter_session", {
      serie_id: serie.SerieId,
      filter_history: true,
      filter_groups: true,
    });
    filters.value = resp.filters;
    filter_history.value = resp.filter_history;
    Object.assign(filter_groups, resp.filter_groups);
  }

  async function load_cats(client: WSClient): Promise<void> {
    const resp = await client.queryWs<{ variables_categories: Cat[] }>("GET", "variable_varCatOfSerie", {
      serie_id: serie.SerieId,
    });
    categories.value = resp.variables_categories;
  }

  async function load_vars(client: WSClient): Promise<Variable[]> {
    if (variables.value !== undefined) return Promise.resolve(variables.value);
    const resp = await client.queryWs<{ variables: Variable[] }>("GET", "variable_variable", {
      var_count: serie.NumberOfPatients < 5000,
      category: true,
      with_not_analysed: true,
      with_picture_exists: true,
      all_data: true,
      sid: serie.SerieId,
    });
    variables.value = resp.variables;
    return Promise.resolve(variables.value);
  }

  return {
    serie_user_permissions,
    serie,
    serie_roles,
    descriptive,
    categories,
    variables,
    filters,
    filter_history,
    filter_groups,
    new_patient_created,
    shared_categorie_ids,
    set_of_visible_cats,
    set_of_visible_vars,
    get_user_permissions,
    load_serie,
    load_filter,
    load_cats,
    load_vars,
  };
});
