import { WSClient, ApiMessage } from "@/ems/WSClient";
import { getFirstParam, parseIntId } from "@/ems/utils";
import router from "@/router/index";

export async function checkUserSerieOrPermissions(permissions: string[]): Promise<boolean> {
  const local_client = new WSClient();

  const route = router.currentRoute.value,
    serie_id = parseIntId(getFirstParam(route.params.series_id) ?? "", 0);

  const resp = await local_client.queryWs<Record<string, boolean>>("GET", "user_permission", {
    sid: serie_id,
    permission_name: permissions.join("|"),
  });
  for (let index = 0; index < permissions.length; index++) {
    const permission = permissions[index];
    if (resp[permission] ?? false) return true;
  }
  return router.push({ name: "NotAllowedInSerie" });
}

export async function checkUserSeriePermission(permission: string): Promise<boolean> {
  const local_client = new WSClient();

  const route = router.currentRoute.value,
    serie_id = parseIntId(getFirstParam(route.params.series_id) ?? "", 0);

  const resp = await local_client.queryWs<Record<string, boolean>>("GET", "user_permission", {
    sid: serie_id,
    permission_name: permission,
  });
  const allowed = resp[permission] ?? false;
  if (allowed) return true;
  return router.push({ name: "NotAllowedInSerie" });
}

export async function checkUserHasPermission(permission: string): Promise<boolean> {
  const local_client = new WSClient();

  const route = router.currentRoute.value,
    serie_id = parseIntId(getFirstParam(route.params.series_id) ?? "", 0);

  const resp = await local_client.queryWs<Record<string, boolean>>("GET", "user_permission", {
    sid: serie_id,
    permission_name: permission,
  });
  return resp[permission] ?? false;
}

export function getUserPermission(
  client: WSClient,
  permissions: string | string[],
  serie_id: string | number | undefined = undefined
): Promise<Record<string, boolean>> {
  if (Array.isArray(permissions)) permissions = permissions.join("|");

  const params = {
    permission_name: permissions,
  } as Record<string, string | number>;

  if (serie_id) params.model_id = serie_id;

  return client.queryWs<Record<string, boolean>>("GET", "user_permission", params);
}

export interface Role {
  id: number;
  name: string;
  display_name: string;
  description: string;
  author: number;
  created_at: string;
  updated_at: string;
  isPlan: number;
  type: any;
  model_type: string;
  model_id: number;
  organization_id: any;
}

export function getUserRoles(client: WSClient): Promise<Role[]> {
  return client.queryWs<{ roles: Role[]; messages: ApiMessage[] }>("GET", "user_UserRole").then((res) => {
    return res.roles;
  });
}
