<template>
  <div :class="'import-serie-box' + (highlight ? 'highlight' : '')" @click="clicked">
    <div
      id="drop-area"
      @dragover.prevent="handleHighlight(true)"
      @dragenter.prevent="handleHighlight(true)"
      @dragleave.prevent="handleHighlight(false)"
      @drop.prevent="handleDrop"
    >
      <form action="/file-upload">
        <slot></slot>
        <input
          ref="file"
          type="file"
          accept=".csv, .tsv, .xls, .xlsx, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain"
          style="display: none"
          @change="handleSelect"
        />
      </form>
    </div>
    <Popup ref="popup" width="692px" top="9rem" :shown="uploading" @close="uploading = false">
      <div style="display: flex; flex-direction: column">
        <div style="height: 109px; width: 109px; border-radius: 54px; background-color: #ddf5ed; margin: 0 auto">
          <img style="margin-top: 28px" alt="Upload arrow" src="@/assets/svg/arrow_upload.svg" />
        </div>
        <div style="font-size: 1.7em; margin: 1em 0">
          Importing
          <b id="fileName">{{ fileName }}</b>
        </div>
        <div>
          <div style="margin: 1em 0">Uploading</div>
          <Process :ratio="upload_ratio"></Process>
          <div style="margin: 1em 0">Analysing</div>
          <Process :ratio="analysis_ratio"></Process>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import Popup from "../components/Popup.vue";
import Process from "./Process.vue";
import { freshworksEvent } from "@/ems/api_functions";
import { useMixPanelEvent } from "@/hooks/useMixPanelEvent.hook";
import { ApiMessage, WSClient } from "@/ems/WSClient";
import { userSessionStore } from "@/store/user_session";

interface Props {
  team_to_share_with?: number;
  organization_id?: number;
  ignore_limit?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["highlight", "message"]);

const { trackEvent } = useMixPanelEvent();
const user_session_store = userSessionStore();
const fileName = ref("file");
const file = ref<null | { click: () => void }>(null);
const highlight = ref(false);
const progress = ref(0);
const messages = ref<ApiMessage[]>([]);
const uploading = ref(false);
const wsclient = new WSClient(uploading, messages);
const remote_datafile_url = ref("");
const upload_ratio = ref(0);
const analysis_ratio = ref(0);
const create_max_limit = ref(-1);

const router = useRouter();
const clicked = (e: Event) => {
  if (file.value) {
    file.value.click();
  }
};

const handleDrop = (e: DragEvent) => {
  handleFiles(e.dataTransfer?.files);
};

const handleSelect = (e: Event) => {
  handleFiles((e.target as HTMLInputElement).files);
};

const handleFiles = async (files: FileList | undefined | null) => {
  if (!files) {
    alert("No files found");
    return;
  }
  const urlRes = await wsclient.queryWs<{ remote_datafile_url: string }>("GET", "env_param", {
    key: "remote_datafile_url",
  });

  const prms: { name: string; model_id?: number; model_type?: string } = {
    name: "serie-patients:create__max-limit",
  };

  if (props.organization_id ?? 0) {
    prms.model_id = props.organization_id;
    prms.model_type = "Organization";
  }
  const maxResp = await wsclient.queryWs<{ serie_patients_create__max_limit: number }>("GET", "permission_value", prms);
  create_max_limit.value = maxResp["serie-patients:create__max-limit" as keyof typeof maxResp] || -1;

  if (!urlRes.remote_datafile_url) {
    alert("Datafile URL not configured");
  } else {
    remote_datafile_url.value = urlRes.remote_datafile_url;
    let all_files = Array.from(files);
    for (const file of all_files) {
      await uploadFile(file);
    }
  }
};

const uploadFile = async (file: File) => {
  uploading.value = true;
  const url = remote_datafile_url.value + "/datafile/";
  const formData = new FormData();
  const dataFileId = Math.floor(Math.random() * Math.floor(100000));

  formData.append("file", file);
  formData.append("id", String(dataFileId));

  fileName.value = file.name;
  messages.value = [];

  const xhr = new XMLHttpRequest();
  const jwt = user_session_store.token;

  xhr.onload = function () {
    if (!uploading.value) {
      trackEvent("upload_cancelled");
      return;
    }

    uploading.value = false;

    if (this.status !== 200) {
      let clientMessages;
      trackEvent("upload_error");
      if (this.getResponseHeader("Content-type") === "application/json") {
        const content = JSON.parse(this.responseText);
        clientMessages = content.messages;
        emit("message", clientMessages);
      } else {
        clientMessages = [{ level: "danger", text: "File upload : " + this.responseText }];
        emit("message", clientMessages);
      }
    } else {
      trackEvent("upload_success");
      const qParams: { ignore_limit?: number; shared_with?: number; organization_id?: number } = {};
      if (props.ignore_limit) qParams.ignore_limit = Number(props.ignore_limit);
      if (parseInt(props.team_to_share_with?.toString() ?? "") > 0) qParams.shared_with = props.team_to_share_with;
      if (parseInt(props.organization_id?.toString() ?? "") > 0) qParams.organization_id = props.organization_id;
      router.push({
        path: "/import/" + dataFileId,
        query: qParams,
      });
    }
  };

  xhr.onerror = function (e) {
    trackEvent("upload_error");
    emit("message", [
      {
        level: "danger",
        text:
          e instanceof ProgressEvent
            ? `We found a problem during the upload of your file. Please contact our support team at support@easymedstat.com.`
            : (e as Error).message,
      },
    ]);
  };

  xhr.ontimeout = function () {
    trackEvent("upload_timeout");

    emit("message", [
      {
        level: "danger",
        text: "Hmmm, it seems that your series is too big for direct web import. Please contact our support team at support@easymedstat.com",
      },
    ]);
  };

  let treatmentCompletion = 0;
  const treatmentInterval = 500;
  let percentPerInterval = 0;

  const addProgress = () => {
    if (treatmentCompletion < 100) {
      treatmentCompletion += percentPerInterval;
    }
    if (treatmentCompletion > 100) treatmentCompletion = 100;
    analysis_ratio.value = Math.round(treatmentCompletion);
  };

  xhr.upload.onprogress = (event) => {
    upload_ratio.value = Math.round((event.loaded * 100) / +event.total);
    if (upload_ratio.value > 98 && percentPerInterval === 0) {
      const totalTime = 13000 + 0.064 * event.total;
      percentPerInterval = (treatmentInterval * 100) / totalTime;
      setInterval(addProgress, treatmentInterval);
    }
  };

  xhr.open("POST", url);
  if (jwt) xhr.setRequestHeader("Authorization", "Bearer " + jwt);
  uploading.value = true;
  xhr.send(formData);

  trackEvent("upload_started");
  freshworksEvent("Import started", {
    datafile_id: dataFileId,
  });
};

const handleHighlight = (state: boolean) => {
  highlight.value = state;
  emit("highlight", state);
};
</script>

<style scoped>
#analysis {
  display: "inline-block";
  background-color: "#008A65";
  height: "100%";
}

#fileName {
  word-break: break-all;
}

.import-serie-box {
  padding: 15px 150px;
  border-radius: 8px;
  border: 3px dashed var(--Borders, #f2f2f2);
  background: var(--Background, #fafafa);
}

.import-serie-box.highlight {
  border-color: #00755d;
  background-color: #00755d;
  opacity: 0.1;
}

#drop-area label {
  cursor: pointer;
}
</style>
