<template>
  <div class="login_box box">
    <form id="loginform" role="form" method="post" @submit.prevent="login" aria-label="Login form">
      <h2 style="margin-top: 0">Sign in</h2>

      <slot></slot>

      <Messages :messages="messages" />
      <template v-if="!messages || messages.length == 0">
        <div v-if="error == 409 || error == 7" class="alert alert-danger">
          <p style="margin-top: 0; font-weight: bold">
            You have been disconnected because someone else is using your account.
          </p>
          <p>Only one user can be connected with this email.</p>
          <p>
            If you don't know why this message appears, please change your password quickly. Your account may have been
            compromised.
          </p>
        </div>
        <div v-else-if="error == 401 || error == 8" class="alert alert-danger">
          Please, sign in to access this page.
        </div>
      </template>

      <div>
        <label for="Email">Email:</label>
        <input
          type="text"
          class="form-control"
          placeholder="Email"
          autocomplete="username"
          name="Email"
          id="Email"
          v-model="email"
          required
          autofocus
        />
      </div>

      <div style="margin-top: 2em">
        <label for="pass">Password:</label>
        <PasswordInput
          name="pass"
          :ignore_strength="true"
          :password="password"
          @change="set_password($event)"
          placeholder="Password"
        >
        </PasswordInput>
      </div>

      <div style="display: flex; justify-content: space-between; align-items: baseline; margin-top: 2em">
        <router-link to="/signup">Create an account</router-link>
        <div>
          <a @click.prevent="password_recall">Lost password ?</a>
          <input type="hidden" name="site" value="app" />
          <ButtonWithIndicator style="margin-left: 1em" btn_style="primary" :show_indicator="loading" type="submit"
            >Sign in</ButtonWithIndicator
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import router from "@/router/index";
import Messages from "@/components/Messages.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import ButtonWithIndicator from "@/components/ButtonWithIndicator.vue";
import { ApiMessage, WSClient } from "@/ems/WSClient";
import { useStore } from "vuex";
import { userSessionStore } from "@/store/user_session";
import { LoggedUser } from "@/types/user";

const store = useStore();
const piniaStore = userSessionStore();

const props = defineProps<{
  initial_messages?: ApiMessage[];
  initial_email?: string;
  redir_on_success?: string;
  error?: number;
}>();

const email = ref(""),
  password = ref(""),
  loading = ref(false),
  messages = ref<ApiMessage[]>([]),
  client = new WSClient(loading, messages);

const login = async () => {
    if (!email.value) {
      messages.value = [{ level: "error", text: "Email required" }];
      return;
    }
    if (!password.value) {
      messages.value = [{ level: "error", text: "Password required" }];
      return;
    }
    messages.value = [];

    const login_resp = await client.queryWs<{ jwt: string; logged_user: LoggedUser }>("POST", "session_login", null, {
      login: email.value,
      pass: password.value,
    });
    if (client.status_code === undefined || (client.status_code < 200 && client.status_code >= 300)) {
      return;
    }
    piniaStore.status = "success";
    piniaStore.logged_user = login_resp.logged_user;
    piniaStore.token = login_resp.jwt;

    const ug = (<any>window).userGuiding;
    if (ug && piniaStore.logged_user?.UserId)
      ug.identify(piniaStore.logged_user.UserId, {
        email: email.value,
      });
    if (props.redir_on_success && props.redir_on_success != "reset") {
      router.goto(props.redir_on_success);
    } else {
      const vueRedir = sessionStorage.getItem("afterlogin");
      if (vueRedir) {
        router.push(JSON.parse(vueRedir));
      } else {
        router.push({ name: "SeriesList" });
      }
    }
    store.commit("auth_success", login_resp);
  },
  password_recall = () => {
    router.push({ name: "ask_password", params: { email: email.value } });
  },
  set_password = (inStr: string) => {
    password.value = inStr;
    console.log("Set password");
  };

watch(
  () => props.redir_on_success,
  (redir) => {
    if (redir == "reset") {
      console.log("Reset afterlogin in login");
      sessionStorage.removeItem("afterlogin");
    }
  },
  { immediate: true }
);

watch(
  () => props.initial_messages,
  (init_messages) => {
    messages.value = init_messages ?? [];
  },
  { immediate: true }
);

watch(
  () => props.initial_email,
  (mail) => {
    if (mail) email.value = mail;
  },
  { immediate: true }
);

onMounted(() => {
  console.log("Mount Login with redir", props.redir_on_success);
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login_box {
  margin: 7.5rem auto;
  max-width: 45em;
  padding: 4em 5em;
  text-align: left;
}

h2 {
  font-size: 2.14em;
}

label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}

.password {
  background-color: transparent;
  border: 0;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  font-size: 1em;
}
</style>
